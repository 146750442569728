import Vue from 'vue';
import axios from 'axios';
import store from '@/store';

// Full config:  https://github.com/axios/axios#request-config
// axios.defaults.baseURL = process.env.baseURL || process.env.apiUrl || '';
// axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

const config = {
  baseURL: process.env.VUE_APP_DOMAIN,
  // timeout: 60 * 1000, // Timeout
  // withCredentials: true, // Check cross-site Access-Control
};

export const customAxios = axios.create(config);

customAxios.interceptors.request.use(
  (requestConfig) => {
    const request = { ...requestConfig };

    // if (store.getters.isAuthorized) {
      request.headers.Authorization = `Bearer ${store.state.auth.accessToken}`;
    // }

    return request;
  },
  (error) => Promise.reject(error),
);

// Add a response interceptor
customAxios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401 || error.response.status === 403) {
      // AuthService.logout();
      //
      // window.location = '/login';
      //
      // return false;
    }

    return Promise.reject(error);
  },
);

// eslint-disable-next-line no-shadow
Plugin.install = function install(Vue /* , options */) {
  // eslint-disable-next-line no-param-reassign
  Vue.axios = customAxios;
  window.axios = customAxios;
  Object.defineProperties(Vue.prototype, {
    axios: {
      get() {
        return customAxios;
      },
    },
    $axios: {
      get() {
        return customAxios;
      },
    },
  });
};

Vue.use(Plugin);

export default Plugin;
