import store from './store';
import urls from './router/urls';
import { customAxios } from './plugins/axios';

function getTokenData(accessToken) {
  const payload = accessToken.split('.')[1];
  let x = JSON.parse(atob(payload));
  // console.log('x', x);

  return x
}

async function setAuthData(accessToken) {
  const {email, id, roles} = getTokenData(accessToken);
  // eslint-disable-next-line max-len
  //const { roles } = services.filter(({ serviceUrl }) => process.env.VUE_APP_SERVICE_URL === serviceUrl)[0];
  //fixed by Alexey M.
  // const roles = ["ROLE_USER"];
  const auth = {
    accessToken: accessToken,
    user: {
      email,
      id,
      roles,
    },
  };
  store.commit('set', ['auth', auth]);
  localStorage.setItem('accessToken', accessToken);
}

export default class {
  static getBearer() {
    return `Bearer ${store.state.auth.accessToken}`;
  }

  static isAccessTokenExpired(accessToken) {
    const tokenData = getTokenData(accessToken);
    const accessTokenExp = tokenData.exp - 10;

    return accessTokenExp <= Math.floor(new Date().getTime() / 1000);
  }

  static async loginByAccessToken() {
      const accessToken = localStorage.getItem('accessToken');

      if (accessToken) {
        if (this.isAccessTokenExpired(accessToken)) {
          this.logout();
        } else {
          await setAuthData(accessToken);
        }
      } else {
        const url = new URL(window.location.href);
        const accessToken = url.searchParams.get('accessToken');
        // Parse the URL string
        if (accessToken !== undefined) {
          await setAuthData(accessToken);
        } else {
          this.logout();
        }
      }
  }

  static async loginByEmailAndPassword({ email, password }) {
    const data = {
      email,
      password,
    };

    const requestConfig = {};

    if (process.env.VUE_APP_X_ORIGIN_DEV) {
      requestConfig.headers = {
        'X-Origin-Dev': process.env.VUE_APP_X_ORIGIN_DEV,
      };
    }

    const response = await customAxios.post(urls.urlLogin, data, requestConfig);

    await setAuthData(response.data.data.token);
  }

  static async loginByInsalesTokens(url) {
    const response = await axios.get(url).catch(function (error) {
      if (error.response) {
        if (error.response.status === 401) {
          window.location.href = urls.urlDenied;
          setTimeout(() => {  console.log("access denied!"); }, 2000);
        }
      }
    });
    await setAuthData(response.data.accessToken);
  }

  static logout() {
    this.resetAuthData();
  }

  // static async refreshTokens() {
  //   try {
  //
  //   } catch (e) {
  //     this.resetAuthData();
  //   }
  // }

  static resetAuthData() {
    const auth = {
      accessToken: null,
      user: {
        email: null,
        id: null,
        roles: [],
      },
    };

    store.commit('set', ['auth', auth]);

    localStorage.removeItem('accessToken');
  }
}
