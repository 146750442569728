import {
  cilAccountLogout,
  cilBolt,
  cilCalendar,
  cilCart,
  cilLifeRing,
  cilList,
  cilDescription,
  cilEnvelopeClosed,
  cilLockLocked,
  cilSettings,
  cilUser,
  cilX,
  cilExternalLink,
  cilCheck,
  cilSync
} from '@coreui/icons';

import { logoFull, logoMinimized } from './logo';

export default {
  ...{},
  ...{
    cilAccountLogout,
    cilBolt,
    cilCalendar,
    cilCart,
    cilLifeRing,
    cilList,
    cilDescription,
    cilEnvelopeClosed,
    cilLockLocked,
    cilSettings,
    cilUser,
    cilX,
    cilExternalLink,
    cilCheck,
    cilSync
  },
  ...{
    logoFull,
    logoMinimized,
  },
};
