export default {
  urlRegisterKaspiAccount: '/api/v1/registration', // регистрация каспи-аккаунта - пост
  urlAccountStatus: '/api/v1/core/account/status', // проверка настроен аккаунт или нет - гет
  urlTradePoints: '/api/v1/core/tradepoints', // список торговых точек - гет
  urlUpdateTradePoint: '/api/v1/core/tradepoints/select/', // сохранить торговую точку - put {tradepointId}
  urlInsaleslogin: '/insales/login', //  - гет
  urlInsalesAutologin: '/insales/autologin', //  - гет
  urlGetOrders: '/api/v1/core/payments/list', //  - гет
  urlGetWhitelist: '/insales/whitelist', //  - гет
  urlEnableWhitelist: '/insales/whitelist/enable', //  - post
  urlDocumentation: 'https://docs.api-soft.ru/kaspipay/',
  urlRefundCreate: '/api/v1/core/payments/refund/create', //  - post
  urlMailtoSupport: 'support@api-soft.com', //  - post
  urlLogin: `${process.env.VUE_APP_AUTH_SERVICE_DOMAIN}/admin/login`,
  urlDenied: `${process.env.VUE_APP_AUTH_SERVICE_DOMAIN}/admin/denied`,
};
