// Example of SVG converted to js array, so it can be used with CIcon.
// the first argument is two last values of svg viewBox,
// the second argument is the SVG content stripped of SVG tags
export const logoFull = ['200 63', `
  <g>
<path class="st0" d="M37.8,38.09c-0.69-4.55-4.95-5.68-7.98-5.5c-1.52,0.09-2.65,1.25-2.97,3.2c-0.34,2.01-0.56,4.06-0.59,6.1
c-0.05,3.71-0.01,7.43,0.15,11.15c0.1,2.44,0.08,4.98,1.92,6.97c2.79,0,5.58,0,8.38,0c0.26-0.08,0.52-0.17,0.79-0.24
c1.7-0.44,2.53-1.45,2.56-3.18c0.01-0.68,0.05-1.37-0.04-2.05C39.3,49.05,38.62,43.56,37.8,38.09z"/>
<path class="st0" d="M58.13,17.15C52.24,4.99,38.98-1.82,25.47,0.42C12.38,2.59,2.28,12.87,0.35,25.75
C-1.7,39.39,5.73,50.19,12.75,54.71c0.05-0.08,0.13-0.16,0.16-0.25c0.09-0.24,0.17-0.48,0.23-0.72c1.21-4.94,1.17-9.86-0.14-14.77
c-0.59-2.2-1-4.43-0.32-6.65c0.86-2.83,1.29-5.7,1.36-8.65c0.05-2.35,0.75-4.45,2.67-6.03c1.1-0.91,1.4-2.05,1.01-3.53
c-0.8-3.09,2.08-6.66,5.31-6.76c1.44-0.04,2.22,0.91,1.87,2.31c-0.07,0.27-0.13,0.55-0.27,0.79c-0.47,0.81-0.93,1.62-1.46,2.38
c-1.53,2.2-1.4,3.24,0.68,4.87c0.94,0.73,1.72,1.59,2.17,2.69c0.73,1.8,1.45,3.61,2.1,5.44c0.41,1.15,1.14,1.96,2.26,2.28
c3.21,0.9,6.28,0.68,9.05-1.36c1.28-0.95,1.52-1.86,1-3.38c-0.18-0.52-0.38-1.07-0.39-1.61c-0.03-2.34,2.51-5.23,4.84-5.55
c0.81-0.11,1.62-0.04,2.14,0.66c0.54,0.73,0.37,1.53-0.04,2.29c-0.6,1.11-1.23,2.21-1.79,3.35c-0.3,0.62-0.79,1.37-0.18,1.96
c0.56,0.55,1.33,0.14,1.92-0.17c1.88-0.99,3.56-2.24,4.79-4.01c0.53-0.77,1.01-1.57,1.61-2.28c0.46-0.54,1.14-0.65,1.82-0.36
c0.66,0.28,0.99,0.86,0.93,1.52c-0.06,0.69-0.18,1.43-0.51,2.02c-0.54,0.93-1.23,1.79-1.93,2.62c-1.56,1.83-3.2,3.59-4.74,5.43
c-0.84,1-1.35,2.13-1.29,3.53c0.25,5.91,2.34,11.18,5.47,16.1c0.2,0.31,0.48,0.57,0.76,0.9C60.79,42.04,63.89,29.05,58.13,17.15z"
/>
<path class="st0" d="M45.66,45c-0.73-0.68-1.45-0.51-1.62,0.46c-0.49,2.84-0.07,7.86,0.87,10.12c0.38,0.9,0.72,1.02,1.57,0.5
c0.71-0.44,1.41-0.9,2.09-1.38c1.02-0.71,1.35-1.43,0.93-2.6C48.92,50.51,48.09,47.25,45.66,45z"/>
<path class="st0" d="M19.94,44.45c-0.34-0.66-0.73-0.63-1.06,0.05c-0.19,0.38-0.35,0.8-0.39,1.21c-0.3,2.98-0.66,7.17,0.15,11.43
c0.16,0.87,0.68,1.38,1.56,1.63c0.54,0.15,0.88,0.1,0.94-0.46c0.55-5.15-0.18-9.27-0.58-12.09C20.46,45.61,20.22,45,19.94,44.45z"
/>
</g>
<g>
<path class="st0" d="M77.05,9.73l5.11-6.02h3.25l-4.83,5.72l5.2,8.09h-3.25l-3.82-6.37l-1.67,2.12v4.25h-2.8V3.71h2.8V9.73z"/>
<path class="st0" d="M89.81,15.01l-0.9,2.52h-2.79l5.11-13.81h2.18l5.11,13.81h-2.79l-0.9-2.52H89.81z M92.32,7.17l-1.95,5.71
h3.89L92.32,7.17z"/>
<path class="st0" d="M124.38,3.71h2.8v13.81h-2.8V3.71z"/>
<path class="st0" d="M86.53,25.57l-12.91,0v31.12h6.57V45.56l7.7,0.01c4.56,0,10.16-3.15,10.16-9.67v0
C98.06,29.72,93.39,25.57,86.53,25.57z M90.03,39.29c-0.88,0.95-1.97,1.42-3.28,1.42H80.5v-9.86h6.26c1.31,0,2.4,0.48,3.28,1.44
c0.88,0.96,1.31,2.13,1.31,3.51C91.35,37.18,90.91,38.34,90.03,39.29z"/>
<path class="st0" d="M135.34,39.67l6.63-14.11H149l-10.5,20.01v11.11h-6.32V45.58l-10.5-20.01h7.03L135.34,39.67z"/>
<path class="st0" d="M113.33,25.57h-5.68L95.81,56.69h6.77l2.33-6.58h11.16l2.33,6.58h6.77L113.33,25.57z M106.66,45.26
l3.68-11.45l3.97,11.45H106.66z"/>
<path class="st0" d="M108.86,5.13c-0.53,0.57-1.01,1.08-1.52,1.62c-1.17-0.76-2.38-1.08-3.72-0.78c-0.89,0.2-1.42,0.79-1.45,1.61
c-0.03,0.81,0.38,1.33,1.36,1.56c0.91,0.21,1.85,0.32,2.77,0.5c1.78,0.36,2.77,1.41,2.99,3.16c0.23,1.84-0.57,3.44-2.1,4.18
c-2.64,1.28-6.24,0.8-7.99-1.04c0.53-0.52,1.05-1.05,1.63-1.63c1.16,0.88,2.57,1.15,4.08,0.98c1.38-0.16,2.15-1.25,1.71-2.37
c-0.24-0.62-0.73-0.88-1.33-0.99c-0.87-0.15-1.76-0.27-2.63-0.44c-1.68-0.33-2.71-1.32-3-2.83c-0.32-1.7,0.35-3.39,1.68-4.26
c1.98-1.29,5.52-1.06,7.31,0.48C108.73,4.95,108.78,5.04,108.86,5.13z"/>
<path class="st0" d="M117.31,3.68l-5.34,0v13.84h2.72v-5.25l3.19,0c1.89,0,4.2-1.4,4.2-4.3v0C122.08,5.23,120.15,3.68,117.31,3.68
z M118.76,9.39c-0.36,0.38-0.81,0.57-1.36,0.57h-2.74V6.03h2.74c0.54,0,0.99,0.19,1.36,0.57c0.36,0.38,0.54,0.85,0.54,1.4
C119.3,8.55,119.12,9.01,118.76,9.39z"/>
</g>
</g>
`];

export const logoMinimized = ['75 63', `
  <g id="Document" fill="none" stroke="black" font-family="Times New Roman" font-size="16" transform="scale(1 -1)">
  <g id="Spread" transform="translate(0 -67.5)">
   <g id="Layer 3">
    <g id="Group">
     <g id="Group_1">
     </g>
    </g>
   </g>
  </g>
 </g>
`];
