import authorized from './middleware/authorized';
import publicAccess from './middleware/publicAccess';
import autoLogin from './middleware/autoLogin';

export default function () {
  return [
    {
      children: [
        // Настройки
        {
          children: [
            {
              component: () => import('../views/admin/settings/General.vue'),
              meta: {
                label: 'Настройки',
                middleware: [
                  authorized,
                ],
                title: 'Настройки',
              },
              name: 'admin.settings',
              path: '',
            },
          ],
          component: () => import('../views/admin/settings/General.vue'),
          path: 'settings/',
        },
        // Чеки
        {
          children: [
            {
              component: () => import('../views/admin/payments/Detail.vue'),
              meta: {
                label: 'Просмотр операции',
                middleware: [
                  authorized,
                ],
              },
              name: 'admin.payments.detail',
              path: ':id',
            },
            {
              component: () => import('../views/admin/payments/List.vue'),
              meta: {
                label: 'Операции',
                middleware: [
                  authorized,
                ],
                title: 'Операции',
              },
              name: 'admin.payments.list',
              path: '',
            },
          ],
          component: () => import('../views/admin/payments/Index.vue'),
          path: 'payments/',
        },
        {
          component: () => import('../views/admin/help/Index.vue'),
          meta: {
            middleware: [
              authorized,
            ],
          },
          name: 'admin.help',
          title: 'Помощь',
          path: 'help/',
        },

      ],
      component: () => import('../containers/TheContainer.vue'),
      path: '/admin',
    },
    // Главная страница
    {
      component: () => import('../views/Index.vue'),
      meta: {
        middleware: [
          authorized,
        ],
      },
      name: 'index',
      path: '/',
    },
    // Вход в личный кабинет
    {
      component: () => import('../views/Login.vue'),
      meta: {
        middleware: [
          publicAccess,
        ],
        title: 'Вход',
      },
      name: 'login',
      path: '/admin/login',
    },
    // Страница запрета входа
    {
      component: () => import('../views/Denied.vue'),
      meta: {
        middleware: [
          publicAccess,
        ],
        title: 'Доступ запрещен',
      },
      name: 'denied',
      path: '/admin/denied',
    },
    // Выход из личного кабинета
    {
      component: () => import('../views/admin/Logout.vue'),
      meta: {
        middleware: [
          authorized,
        ],
      },
      name: 'admin.logout',
      path: '/admin/logout',
    },
    {
      component: () => import('../views/AutoLogin.vue'),
      meta: {
        middleware: [
          autoLogin,
        ],
      },
      name: 'autologin',
      path: '/f/login',
    },
    {
      component: () => import('../views/AutoLoginNext.vue'),
      meta: {
        middleware: [
          autoLogin,
        ],
      },
      name: 'autologinNext',
      path: '/f/autologin',
    },
  ];
}
