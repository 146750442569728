import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

const getters = {
  isAuthorized: (state) => !!state.auth.accessToken,
  isConfigured: (state) => state.isConfigured,
  isAdmin: (state) => state.auth.user.roles.includes('ROLE_USER'),
  isUserOwner: (state) => state.auth.user.roles.includes('ROLE_OWNER'),
  loginParams: (state) => {
    // console.log('loginParams', state.loginParams);
    return state.loginParams;
  }
};

const mutations = {
  set(state, [variable, value]) {
    state[variable] = value;
  },
  toggleSidebarDesktop(state) {
    const sidebarOpened = [true, 'responsive'].includes(state.sidebarShow);
    state.sidebarShow = sidebarOpened ? false : 'responsive';
  },
  toggleSidebarMobile(state) {
    const sidebarClosed = [false, 'responsive'].includes(state.sidebarShow);
    state.sidebarShow = sidebarClosed ? true : 'responsive';
  },
};

export default new Vuex.Store({
  getters,
  mutations,
  state: {
    auth: {
      accessToken: null,
      user: {
        email: null,
        id: null,
        roles: [],
      },
    },
    sidebarMinimize: false,
    sidebarShow: 'responsive',
    loginParams: null,
    isConfigured: false,
  },
});
