import store from "@/store";

export default function ({ next, store, nextMiddleware }) {

  // if (store.getters.loginParams === undefined || store.getters.loginParams === null) {
  //   return next({name: 'login'});
  // }

  return nextMiddleware();
}
